// Generated by Framer (e2fa450)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {eFIS_dUbI: {hover: true}};

const serializationHash = "framer-4dRsT"

const variantClassNames = {eFIS_dUbI: "framer-v-1dt3pg1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, newTab, smoothScroll, text, width, ...props}) => { return {...props, LWE8QcWhU: link ?? props.LWE8QcWhU, nw1aYSkVo: text ?? props.nw1aYSkVo ?? "Home", tPd6gtRvS: newTab ?? props.tPd6gtRvS, VyMwIkeg6: smoothScroll ?? props.VyMwIkeg6} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;link?: string;newTab?: boolean;smoothScroll?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nw1aYSkVo, LWE8QcWhU, tPd6gtRvS, VyMwIkeg6, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "eFIS_dUbI", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={LWE8QcWhU} openInNewTab={tPd6gtRvS} smoothScroll={VyMwIkeg6}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1dt3pg1", className, classNames)} framer-c2bred`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"eFIS_dUbI"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"eFIS_dUbI-hover": {opacity: 0.75}}} {...addPropertyOverrides({"eFIS_dUbI-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.03em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Home</motion.p></React.Fragment>} className={"framer-zkk544"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"ARYdJSYTU"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={nw1aYSkVo} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4dRsT.framer-c2bred, .framer-4dRsT .framer-c2bred { display: block; }", ".framer-4dRsT.framer-1dt3pg1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-4dRsT .framer-zkk544 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4dRsT.framer-1dt3pg1 { gap: 0px; } .framer-4dRsT.framer-1dt3pg1 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-4dRsT.framer-1dt3pg1 > :first-child { margin-top: 0px; } .framer-4dRsT.framer-1dt3pg1 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 47
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"hLrh66TFn":{"layout":["auto","auto"]}}}
 * @framerVariables {"nw1aYSkVo":"text","LWE8QcWhU":"link","tPd6gtRvS":"newTab","VyMwIkeg6":"smoothScroll"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVdS0p935x: React.ComponentType<Props> = withCSS(Component, css, "framer-4dRsT") as typeof Component;
export default FramerVdS0p935x;

FramerVdS0p935x.displayName = "Navlink";

FramerVdS0p935x.defaultProps = {height: 23, width: 47};

addPropertyControls(FramerVdS0p935x, {nw1aYSkVo: {defaultValue: "Home", displayTextArea: false, title: "Text", type: ControlType.String}, LWE8QcWhU: {title: "Link", type: ControlType.Link}, tPd6gtRvS: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, VyMwIkeg6: {defaultValue: false, title: "Smooth Scroll", type: ControlType.Boolean}} as any)

addFonts(FramerVdS0p935x, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})